import axios from 'axios'

class HireReqApi {
  async get(page, limit) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .get(`/hireRequest/page/${page}/limit/${limit}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }


  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .delete(`/hireRequest/hireRequest_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        console.log('error', error)

        return error.response
      })
  }
}


export default new HireReqApi()
